$primary-colour: #1076EF;
$secondary-colour: #EF8910;
$black-colour: #3c3c3c;
$yellow-colour: #ffff47;

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Gaegu', cursive;
  font-weight: 400;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  text-align: center;
  line-height: 1.05em;
}

h1 {
  font-size: 2.35em;
  margin-top: 10vh;
  margin-bottom: 0;
}

h2 {
  font-size: 2.3em;
  margin-top: 3vh;
  margin-bottom: 0;
}

p {
  font-size: 1.25em;
  line-height: 1.05em;
  text-align: center;
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

/* Logo Text */
#logo {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary-colour;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 1em;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 2.75em;
    font-weight: 700;
  }
}

.defaultPage {
  background-color: $primary-colour;
  width: 100%;
  overflow-x: hidden;

  .letter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background-color: $yellow-colour;
    color: $black-colour;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: .25em;
  }

  label,
  input {
    color: $black-colour;
  }

  input {
    z-index: 1;
    font-size: 1.75em;
  }

  .MuiTextField-root {
    background-color: #fff;
  }
}

/* Pages */
.welcomePage,
.letterPage,
.sessionPage {
  min-height: 100vh;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.letterPage {
  .letter {
    font-size: 5em;
  }
}

.sessionPage {
  .letter {
    font-size: 3em;
    width: 60px;
    height: 60px;
  }

  .answerForm {
    text-align: center;
    margin-top: 1em;

    .MuiTextField-root {
      width: 100%;
    }
  }

  .timer-progress {
    font-size: 1.35em;
  }

  .totalScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .answer-list {
      font-size: 1.5em;
      text-align: center;
    }
  }
}

button.main-button,
a.main-button {
  background-color: $secondary-colour;
  font-family: 'Gaegu', cursive;
  font-size: 1.5em;
  font-weight: 700;
  padding: 5px 20px;
  margin-top: 1em;
  margin-bottom: 2.5em;
  border-radius: 10px;
  z-index: 1;

  &:hover {
    background-color: $secondary-colour;
    filter: brightness(98%);
  }
}

/* Animated Bg */
.squares{
  // position: absolute;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  .square {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

    &:nth-child(1){
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
    }

    &:nth-child(2){
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
    }

    &:nth-child(3){
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
    }

    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }

    &:nth-child(5){
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }

    &:nth-child(6){
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
    }

    &:nth-child(7){
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
    }

    &:nth-child(8){
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }

    &:nth-child(9){
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }

    &:nth-child(10){
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
  }
}

@keyframes animate {
  0%{
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* Overlay */
.overlay {
  position: fixed;
  z-index: 99;
  background-color: $black-colour;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  margin: 0;
  overflow: hidden;

  .countdownCounterCheer {
    font-size: 5em;
    margin: 0;
    margin-top: -100px;
  }

  .countdownCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-colour;
    color: #fff;
    font-family: 'Gaegu', cursive;
    font-size: 7em;
    font-weight: 700;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 50px;
  }
}

.overlayhidden {
  display: none;
}

/* Footer */
footer {
  div {
    // position: absolute;
    position: relative;
    width: 100%;
    height: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // bottom: 0;
    margin-top: -1.55em;
    line-height: 1.05;
    font-size: 1em;
  }
}

@media (min-width: 600px) {
  h1 {
    font-size: 3.5em;
    margin-top: 5vh;
  }

  p {
    font-size: 2em;
  }

  /* Logo Text */
  #logo {
    width: 100px;
    height: 100px;
    margin: 1em;
    a {
      font-size: 4em;
    }
  }

  .defaultPage {
    .letter {
      width: 100px;
      height: 100px;
    }
  }

  .sessionPage {
    .letter {
      font-size: 4em;
    }
  }

  button.main-button,
  a.main-button {
    font-size: 2em;
    padding: 15px 40px;
    margin-top: 2em;
    border-radius: 15px;
  }

  /* Footer */
  footer {
    div {
      font-size: 1.35em;
    }
  }
}

@media (min-width: 900px) {
  h1 {
    font-size: 4em;
    margin-top: 10vh;
  }

  p {
    font-size: 2.7em;
  }

  /* Logo Text */
  #logo {
    width: 120px;
    height: 120px;
    a {
      font-size: 5em;
    }
  }

  button.main-button,
  a.main-button {
    font-size: 2em;
    margin-top: 1.25em;
  }

  .defaultPage {
    background-color: $primary-colour;
    width: 100%;
    overflow-x: hidden;
    .letter {
      width: 150px;
      height: 150px;
      margin-top: .5em;
    }
  }

  .letterPage {
    .letter {
      font-size: 7em;
    }
  }

  .sessionPage {
    .letter {
      font-size: 5em;
    }

    .answerForm {
      margin-top: 2em;
    }
  }
}
